<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row class="px-2 py-2 dense-wrapper">
          <v-col cols="12">
            <v-text-field type="text" hide-details filled label="Назва повна"
                          v-model="name"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="name ? '' : 'req-star'"
                          color="grey"
            />
          </v-col>
          <v-col cols="12" class="d-flex">
            <v-text-field type="text" hide-details filled label="Назва коротка"
                          v-model="short_name"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="short_name ? '' : 'req-star'"
                          color="grey"
                          style="flex: 1; margin-right: 16px"
            />
            <v-text-field type="text" hide-details filled label="Абревіатура"
                          v-model="abbreviation"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="abbreviation ? '' : 'req-star'"
                          color="grey"
                          style="flex: 0 0 154px"
            />
          </v-col>
          <v-col cols="12" md="10">
            <v-select :items="calculateMethodsSelect" filled label="Спосіб розрахунку"
                      hide-details
                      v-model="calculate_method"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="calculate_method ? '' : 'req-star'"
                      color="grey"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-select :items="accountIconSelect" filled
                      hide-details
                      v-model="icon"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="icon ? '' : 'req-star'"
                      return-object
                      @change="changeIcon"
                      color="grey"
            >
              <template v-slot:selection="{ item }">
                <v-icon size="40" :color="item.value.color">
                  {{ item.value.icon }}
                </v-icon>
              </template>
              <template v-slot:item="{ item }">
                <template>
                  <v-list-item-icon>
                    <v-icon size="36" class="mr-3" :color="item.value.color">
                      {{ item.value.icon }}
                    </v-icon>
                  </v-list-item-icon>
                </template>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="12" v-if="calculate_method === 'by_square'">
            <v-select :items="indicators" hide-details filled label="Вид показника"
                      v-model="indicator_id"
                      color="grey"
                      clearable
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-select :items="monetaryServiceSelect" hide-details filled label="Послуга для монетизації"
                      v-model="monetary_service"
                      clearable
            />
          </v-col>
          <v-col cols="12">
            <v-switch
                v-model="has_counter"
                inset
                hide-details
                class="mt-1"
                :label="has_counter ? 'Облік по лічильниках' : 'Лічильники не передбачені'"
            />
          </v-col>
          <v-col cols="12">
            <v-select :items="services_select" filled
                      label="Послуга для показів залежної послуги (водопост. для водовідведення)"
                      hide-details
                      v-model="service_counter_id"
                      clearable
            />
          </v-col>
          <v-col cols="12">
            <v-switch
                v-model="calc_by_norm"
                inset
                hide-details
                class="mt-1"
                :label="calc_by_norm ? 'Розрахунок по нормах включений' : 'Розрахунок по нормах відключений'"
            />
          </v-col>
          <v-col cols="12" md="12">
            <ACC_Nomenclature
                :value="nomenclature_id"
                @autocompleteChange="parentChange"
                color="grey"
                :select_type="'elements'"
                :action_props="{is_service: true}"
                :is_group="false"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field type="text" hide-details filled label="Код послуги (EPS)"
                          v-model="eps_id"
                          color="grey"
                          style="flex: 0 0 154px"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text tile color="success" @click.stop="updateService">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import {accountIcons, calculateMethodsSelect, monetaryServiceSelect, generateIconSelect} from "@/utils/icons";
import {DELETE_SERVICE, UPDATE_SERVICE} from "@/store/actions/services";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = "service_modal_id"

export default {
  name: "HWP_Modal_Service",
  mixins: [ModalComponentMixin],
  components: {
    ACC_Nomenclature: () => import("@/components/accounting/autocomplite/modal/ACC_Nomenclature")
  },
  data() {
    return {
      calculateMethodsSelect,
      accountIcons,
      monetaryServiceSelect,
      calculate_method: this.item.calculate_method,
      service_counter_id: this.item.service_counter_id || null,
      has_counter: this.item.has_counter,
      name: this.item.name,
      calc_by_norm: this.item.calc_by_norm || false,
      icon: accountIcons[this.item.service_icon] || '',
      short_name: this.item.short_name || '',
      indicator_id: this.item.indicator_id || null,
      service_icon: this.item.service_icon || '',
      abbreviation: this.item.abbreviation || '',
      eps_id: this.item.eps_id || 0,
      nomenclature_id: this.item.nomenclature_id || null,
      monetary_service: this.item.monetary_service || null
    }
  },
  computed: {
    ...mapGetters({
      indicators: 'getFlatIndicatorTypes',
      services_select: 'getServicesSelectWithoutAll',
    }),
    accountIconSelect() {
      return this.generateIconSelect()
    }
  },
  methods: {
    generateIconSelect,
    parentChange(payload) {
      this.nomenclature_id = (payload || {})?.value || null
    },
    changeIcon() {
      this.$nextTick(() => {
        const keys = Object.entries(this.accountIcons)
        const item = keys.find(item => {
          return (item[1].icon === this.icon?.value?.icon && item[1].color === this.icon?.value?.color)
        })
        this.service_icon = item.length ? item[0] : ''
      })
    },
    closeModal() {
      const icon_obj = this.accountIcons[this.item.service_icon]
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.calculate_method = this.item.calculate_method
      this.has_counter = this.item.has_counter
      this.name = this.item.name
      this.calc_by_norm = this.item.calc_by_norm
      this.icon = icon_obj ? icon_obj : {}
      this.short_name = this.item.short_name
      this.indicator_id = this.item.indicator_id || null
      this.service_icon = this.item.service_icon
      this.abbreviation = this.item.abbreviation
      this.eps_id = this.item.eps_id || 0
      this.monetary_service = this.item.monetaryServiceSelect || null
      this.service_counter_id = this.item.service_counter_id || null
      this.nomenclature_id = this.item.nomenclature_id || null
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення послуги ${this.name}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    updateService() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Усі поля повинні бути заповнені',
          color: 'error lighten-1'
        })
        return undefined
      }

      const payload = {
        id: this.itemId,
        calculate_type: this.calculate_method,
        has_counter: this.has_counter,
        name: this.name,
        calc_by_norm: this.calc_by_norm,
        icon: this.service_icon,
        short_name: this.short_name,
        indicator_id: this.indicator_id,
        abbreviation: this.abbreviation,
        eps_id: this.eps_id || 0,
        monetary_service: this.monetary_service,
        service_counter_id: this.service_counter_id,
        nomenclature_id: this.nomenclature_id
      }

      this.$store.dispatch(UPDATE_SERVICE, payload)
          .then(el => {
            if (el) {
              this.closeModal()
            }
          })
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.calculate_method = payload.calculate_method
              this.has_counter = payload.has_counter
              this.name = payload.name
              this.calc_by_norm = payload.calc_by_norm
              this.icon = accountIcons[payload.service_icon] || ''
              this.short_name = payload.short_name
              this.indicator_id = payload.indicator_id || null
              this.service_icon = payload.service_icon
              this.abbreviation = payload.abbreviation
              this.eps_id = payload.eps_id || 0
              this.monetary_service = payload.monetary_service || null
              this.service_counter_id = payload.service_counter_id || null
              this.nomenclature_id = payload.nomenclature_id
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_SERVICE, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
